
import React, { useEffect, useMemo, useState } from 'react';
import { CloseIcon } from "../../assets/Icons";
import style from './../globalCSS/querytype/chatbotmodal.module.css';
import { Button } from './../globalCSS/Button/Button';
import { texttoquerychartbot, texttoquerychartbotreset } from '../../actions/reportmanagement';
import { useDispatch, useSelector } from 'react-redux';

const ChatBotModal = ({ showModal, setShowModal, setformdata }) => {
  const [formdata, setFormdata] = useState({ query: '' });
  const [generatedQuery, setGeneratedQuery] = useState(''); // New state for the generated query
  const [errormessage, seterrormessage] = useState("");
  const selectedShemasection = JSON.parse(localStorage.getItem('SelectedSchema'));
  const user = JSON.parse(localStorage.getItem('profile'));
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state);
  const reportdetail = apiData?.reportmanagement?.texttochatbox;

  useEffect(() => {
    dispatch(texttoquerychartbotreset());
    setFormdata({ query: '' });
    setGeneratedQuery(''); // Reset the generated query
    seterrormessage('');
  }, []);

  useMemo(() => {
    if (reportdetail && Object.keys(reportdetail).length > 0) {
      if (reportdetail?.status === 200) {
        setGeneratedQuery(reportdetail?.data.query); // Set the generated query
        seterrormessage('');
      } else {
        seterrormessage(reportdetail?.data.query);
      }
    }
  }, [reportdetail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevFormdata) => ({
      ...prevFormdata,
      [name]: value
    }));
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleGenerateQuery = () => {
    dispatch(texttoquerychartbot({
      question: formdata.query,
      schema: selectedShemasection.selectedSchema,
      mode: "query",
      email: user.user_email_id,
      connection_type: selectedShemasection.databasename,
      database_type: "mysql"
    }));
  };

  const handleConfirmQuery = () => {
    setformdata((prevFormdata) => ({
      ...prevFormdata,
      query: generatedQuery // Confirm the generated query
    }));
    setShowModal(false);
  };

  return (
    <div className={style.recommended_adjustments}>
      <div className={style.popup__box}>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" style={{ textAlign: "center", fontWeight: "600" }} className="form-label">
            Type Text Below
          </label>
          <span>{errormessage}</span>
          <CloseIcon style={{ margin: "1px" }} onClick={handleCloseModal} />
          <div className="input-group flex-nowrap">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              name="query"
              placeholder="Type Text Here...."
              value={formdata.query}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>

        <div className={style.Add_remove_data}>
          <div className={style.save_changes_btn}>
            <Button type='button' onClick={handleGenerateQuery}>Generate Query</Button>
          </div>
        </div>

        {generatedQuery && (
          <div className="mb-3">
            <label htmlFor="generatedQuery" style={{ textAlign: "center", fontWeight: "600" }} className="form-label">
              Generated Query
            </label>
            <div className="input-group flex-nowrap">
              <textarea
                className="form-control"
                id="generatedQuery"
                rows="3"
                name="generatedQuery"
                placeholder="Generated Query Here...."
                value={generatedQuery}
                readOnly
              ></textarea>
            </div>
            <div className={style.Add_remove_data}>
              <div className={style.save_changes_btn}>
                <Button type='button' style={{margin:"15px 0 10px 10px"}} onClick={handleConfirmQuery}>Confirm Query</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBotModal;