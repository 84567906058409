import { LOADED,LOADING,ASSIGN_GROUP_ID_TO_REPORT,GET_REPORT_DETAIL_BASEON_GROUP_ID} from "../constants/actionTypes";
import * as api from '../api/index.js';
import logMessage from "../logserver.js";
import {toast } from 'react-toastify';

const user = JSON.parse(localStorage.getItem('profile'));
export const  assignreporttothegroup= (formData, router) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
        const data = await api.assignreporttothegroup(formData);
        dispatch({type: LOADED });
        if (data?.status === 200) {
            dispatch({ type: ASSIGN_GROUP_ID_TO_REPORT, data });
            toast.success(data?.data || "Report assign successfully", {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,theme: "light",});
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}

export const getReportDetailonbasisOFgroupid= (formData, router) => async (dispatch) => {
    dispatch({type: LOADING});
    try {
        const data = await api.getReportDetailonbasisOFgroupid(formData);
        dispatch({type: LOADED });
        if (data?.status === 200) {
            dispatch({ type: GET_REPORT_DETAIL_BASEON_GROUP_ID, data });
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }else if(data?.status === 204) {
            logMessage(user?.user_email_id,data?.status,data?.statusText);
        }
    }catch (error) {
        console.log(error.message);
      }
}