import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./../globalCSS/reportmanagement/previewpage.css";
import PreviewHighchart from "../PreviewHighchart/PreviewHighchart";
import PreviewReportTable from "../PreviewHighchart/PreviewReportTable";
import Box from "../PreviewHighchart/Box";
import { savereportTemplate } from "../../actions/auth";
import { updateReportdetail } from "../../actions/reportmanagement";
import { useDispatch} from "react-redux";
import { Button } from "./../globalCSS/Button/Button";
import Header from "../header";

function PreviewPage() {
  const insitialstateofcomp = {
    report_name: "",
    report_type: "",
    chart_type: "",
    query: "",
    enable_drilldown: "",
    auto_update_interval: "",
    time_period: "",
    start_date: "",
    end_date: "",
    background_colour: "",
    chart_colours: "",
    chart_react_colour: "",
    font_size_title: "",
    font_size_value: "",
    rule_value: "",
    rule_colour: "",
    rule_mapping: "",
    email: "",
    database_type: "",
    connection_type: "",
    schema: "",
    display_order: 1,
    upload_logo: "",
  };

  const [CustomDetail, setCustomDetail] = useState(insitialstateofcomp);
  const [background_colour1, setBackgroundcolor] = useState("#ffffff");
  const [chart_react_colour1, setChartreactcolour] = useState("#000000");
  const [chart_colours1, setChartcolours] = useState([]);
  const [fontSize, setFontSize] = useState({
    font_size_title: "",
    font_size_value: "",
  });
  const [ruleApplied, setRuleApplied] = useState({
    rule_value: "",
    rule_colour: "#fff",
    rule_mapping: "",
  });
  const [customizationOptionsPreview, setCustomizationOptionsPreview] =
    useState({
      background_colour: "#ffffff",
      chart_react_colour: "#000000",
      chart_colours:{},
      font_size_title: "",
      font_size_value: "",
      rule_value: "",
      rule_colour: "#ff0000",
      rule_mapping: "",
    });

  const [storedetailtoback, setStoredetailtoback] = useState();
  const [dragMe, setDragMe] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();
  const user = JSON.parse(localStorage.getItem("profile")); 
  const CustomeDetailOfReport = JSON.parse(
    localStorage.getItem("customeDetailOfReport")
  );


  useEffect(() => {
    let newstateofcomp;
    const CustomeDetailOfReport = JSON.parse(
      localStorage.getItem("customeDetailOfReport")
    );
    if (CustomeDetailOfReport != null) {
      newstateofcomp = {
        report_template_name: CustomeDetailOfReport?.title,
        report_type: CustomeDetailOfReport?.type,
        chart_type: CustomeDetailOfReport?.chart_type,
        defined_query: CustomeDetailOfReport?.query,
        enable_drilldown: CustomeDetailOfReport?.enable_drilldown,
        auto_update_interval: CustomeDetailOfReport?.update_interval,
        time_period: CustomeDetailOfReport?.time_period,
        start_date: CustomeDetailOfReport?.start_date,
        end_date: CustomeDetailOfReport?.end_date,
        email: user.user_email_id,
        database_type: "mysql",
        connection_type: CustomeDetailOfReport?.connection_type,
        schema: CustomeDetailOfReport?.schema,
        display_order: 1,
      };
      if (
        !CustomeDetailOfReport.report_id &&
        CustomeDetailOfReport.type === "Box"
      ) {
        const newObj = {
          ...newstateofcomp,
    
          background_colour: customizationOptionsPreview.background_colour,
          chart_react_colour: customizationOptionsPreview.chart_react_colour,
          font_size_title: customizationOptionsPreview.font_size_title,
          font_size_value: customizationOptionsPreview.font_size_value,
          rule_value: customizationOptionsPreview.rule_value,
          rule_colour: customizationOptionsPreview.rule_colour,
        };
        const base64String = localStorage.getItem("uploadLogo");
        let formData = new FormData();
        if (base64String) {
         
          fetch(base64String)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], "logo.png", { type: "image/png" });
              formData.append("file", file);
              formData.append("report_template_name", JSON.stringify(newObj));
              setStoredetailtoback({
                ...newstateofcomp,
              
                background_colour:
                  customizationOptionsPreview.background_colour,
                chart_react_colour:
                  customizationOptionsPreview.chart_react_colour,
                font_size_title: customizationOptionsPreview.font_size_title,
                font_size_value: customizationOptionsPreview.font_size_value,
                rule_value: customizationOptionsPreview.rule_value,
                rule_colour: customizationOptionsPreview.rule_colour,
                rule_mapping: customizationOptionsPreview.rule_mapping,
                upload_logo: null,
              });
            });
        } else {
       
          formData.append("report_template_name", JSON.stringify(newObj));
          setStoredetailtoback({
            ...newstateofcomp,
     
            background_colour: customizationOptionsPreview.background_colour,
            chart_react_colour: customizationOptionsPreview.chart_react_colour,
            font_size_title: customizationOptionsPreview.font_size_title,
            font_size_value: customizationOptionsPreview.font_size_value,
            rule_value: customizationOptionsPreview.rule_value,
            rule_colour: customizationOptionsPreview.rule_colour,
            rule_mapping: customizationOptionsPreview.rule_mapping,
            upload_logo: null,
          });
        }
        setCustomDetail(formData);
      } else if (
        !CustomeDetailOfReport.report_id &&
        CustomeDetailOfReport.type === "Table"
      ) {
        let formData = new FormData();
        formData.append("report_template_name", JSON.stringify(newstateofcomp));
        setStoredetailtoback(newstateofcomp);
        setCustomDetail(formData);
      } else if (
        !CustomeDetailOfReport.report_id &&
        CustomeDetailOfReport.type === "Chart"
      ) {
        const newObj = {
          ...newstateofcomp,
     
          chart_colours: (customizationOptionsPreview.chart_colours),
        };
        let formData = new FormData();
     
        formData.append("report_template_name", JSON.stringify(newObj));
        setStoredetailtoback(newObj);
        setCustomDetail(formData);
      } else if (
        CustomeDetailOfReport.report_id &&
        CustomeDetailOfReport.type === "Box"
      ) {
        let localnewObj = {
          ...newstateofcomp,
          report_id: CustomeDetailOfReport.report_id,
          background_colour: CustomeDetailOfReport?.background_colour,
          chart_react_colour: CustomeDetailOfReport?.chart_react_colour,
          font_size_title: CustomeDetailOfReport.font_size_title,
          font_size_value: CustomeDetailOfReport.font_size_value,
          rule_value: CustomeDetailOfReport?.rule_value,
          rule_colour: CustomeDetailOfReport?.rule_colour,
          rule_mapping: CustomeDetailOfReport?.rule_mapping,
        };
        const base64String = localStorage.getItem("uploadLogo");
        let formData = new FormData();
        if (base64String) {
          fetch(base64String)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], "logo.png", { type: "image/png" });
              formData.append("file", file);
              formData.append("details", JSON.stringify(localnewObj));
              setStoredetailtoback({
                ...newstateofcomp,
                report_id: CustomeDetailOfReport.report_id,
           
                background_colour:
                  customizationOptionsPreview.background_colour,
                chart_react_colour:
                  customizationOptionsPreview.chart_react_colour,
                font_size_title: customizationOptionsPreview.font_size_title,
                font_size_value: customizationOptionsPreview.font_size_value,
                rule_value: customizationOptionsPreview.rule_value,
                rule_colour: customizationOptionsPreview.rule_colour,
                rule_mapping: customizationOptionsPreview.rule_mapping,
                upload_logo: CustomeDetailOfReport?.upload_logo,
              });
              setCustomDetail(formData);
            });
        } else {
          localnewObj = {
            ...newstateofcomp,
            report_id: CustomeDetailOfReport.report_id,
            background_colour: CustomeDetailOfReport?.background_colour,
            chart_react_colour: CustomeDetailOfReport?.chart_react_colour,
            upload_logo: CustomeDetailOfReport?.upload_logo,
            font_size_title: CustomeDetailOfReport.font_size_title,
            font_size_value: CustomeDetailOfReport.font_size_value,
       
            rule_value: customizationOptionsPreview.rule_value,
            rule_colour: customizationOptionsPreview.rule_colour,
            rule_mapping: customizationOptionsPreview.rule_mapping,
          };
          formData.append("details", JSON.stringify(localnewObj));
          setStoredetailtoback({
            ...newstateofcomp,
            report_id: CustomeDetailOfReport.report_id,
          
            background_colour: customizationOptionsPreview.background_colour,
            chart_react_colour: customizationOptionsPreview.chart_react_colour,
            font_size_title: customizationOptionsPreview.font_size_title,
            font_size_value: customizationOptionsPreview.font_size_value,
            rule_value: customizationOptionsPreview.rule_value,
            rule_colour: customizationOptionsPreview.rule_colour,
            rule_mapping: customizationOptionsPreview.rule_mapping,
            upload_logo: CustomeDetailOfReport?.upload_logo,
          });
          setCustomDetail(formData);
        }
      } else if (
        CustomeDetailOfReport.report_id &&
        CustomeDetailOfReport.type === "Table"
      ) {
        const newObj = {
          ...newstateofcomp,
          report_id: CustomeDetailOfReport.report_id,
        };
        let formData = new FormData();
        formData.append("details", JSON.stringify(newObj));
        setCustomDetail(formData);
        setStoredetailtoback(newObj);
      } else if (
        CustomeDetailOfReport.report_id &&
        CustomeDetailOfReport.type === "Chart"
      ) {
        const newObj = {
          ...newstateofcomp,
          report_id: CustomeDetailOfReport.report_id,
      
          chart_colours: (customizationOptionsPreview.chart_colours),
        };
 
        let formData = new FormData();
        formData.append("details", JSON.stringify(newObj));
        setCustomDetail(formData);
        setStoredetailtoback(newObj);
      }
    }
  }, [
  
    customizationOptionsPreview,
  ]);

  useEffect(() => {
    let newstateofcomp;
    const CustomeDetailOfReport = JSON.parse(
      localStorage.getItem("customeDetailOfReport")
    );

    if (CustomeDetailOfReport != null) {
      newstateofcomp = {
        report_template_name: CustomeDetailOfReport?.title,
        report_type: CustomeDetailOfReport?.type,
        chart_type: CustomeDetailOfReport?.chart_type,
        defined_query: CustomeDetailOfReport?.query,
        enable_drilldown: CustomeDetailOfReport?.enable_drilldown,
        auto_update_interval: CustomeDetailOfReport?.update_interval,
        time_period: CustomeDetailOfReport?.time_period,
        start_date: CustomeDetailOfReport?.start_date,
        end_date: CustomeDetailOfReport?.end_date,
        email: user.user_email_id,
        database_type: "mysql",
        connection_type: CustomeDetailOfReport?.connection_type,
        schema: CustomeDetailOfReport?.schema,
        display_order: 1,
      };
      if (
        !CustomeDetailOfReport.report_id &&
        CustomeDetailOfReport.type === "Box"
      ) {
        const newObj = {
          ...newstateofcomp,
        
          background_colour: customizationOptionsPreview.background_colour,
          chart_react_colour: customizationOptionsPreview.chart_react_colour,
          font_size_title: customizationOptionsPreview.font_size_title,
          font_size_value: customizationOptionsPreview.font_size_value,
          rule_value: customizationOptionsPreview.rule_value,
          rule_colour: customizationOptionsPreview.rule_colour,
          rule_mapping: customizationOptionsPreview.rule_mapping,
        };
        const base64String = localStorage.getItem("uploadLogo");
        let formData = new FormData();
        if (base64String) {
         
          fetch(base64String)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], "logo.png", { type: "image/png" });
              formData.append("file", file);
              formData.append("report_template_name", JSON.stringify(newObj));
              setStoredetailtoback({
                ...newstateofcomp,
           
                background_colour:
                  customizationOptionsPreview.background_colour,
                chart_react_colour:
                  customizationOptionsPreview.chart_react_colour,
                font_size_title: customizationOptionsPreview.font_size_title,
                font_size_value: customizationOptionsPreview.font_size_value,
                rule_value: customizationOptionsPreview.rule_value,
                rule_colour: customizationOptionsPreview.rule_colour,
                rule_mapping: customizationOptionsPreview.rule_mapping,
                upload_logo: file,
              });
            });
        } else {
          formData.append("report_template_name", JSON.stringify(newObj));
          setStoredetailtoback({
            ...newstateofcomp,
        
            background_colour: customizationOptionsPreview.background_colour,
            chart_react_colour: customizationOptionsPreview.chart_react_colour,
            font_size_title: customizationOptionsPreview.font_size_title,
            font_size_value: customizationOptionsPreview.font_size_value,
            rule_value: customizationOptionsPreview.rule_value,
            rule_colour: customizationOptionsPreview.rule_colour,
            rule_mapping: customizationOptionsPreview.rule_mapping,
            upload_logo: null,
          });
        }
        setCustomDetail(formData);
      } else if (
        CustomeDetailOfReport.report_id &&
        CustomeDetailOfReport.type === "Box"
      ) {
      
        const base64String = localStorage.getItem("uploadLogo");
        const newobj = {
          ...newstateofcomp,
          report_id: CustomeDetailOfReport.report_id,
    
          background_colour: customizationOptionsPreview.background_colour,
          chart_react_colour: customizationOptionsPreview.chart_react_colour,
          font_size_title: customizationOptionsPreview.font_size_title,
          font_size_value: customizationOptionsPreview.font_size_value,
          rule_value: customizationOptionsPreview.rule_value,
          rule_colour: customizationOptionsPreview.rule_colour,
          rule_mapping: customizationOptionsPreview.rule_mapping,
        };
   
        let formData = new FormData();
        if (base64String) {
          fetch(base64String)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], "logo.png", { type: "image/png" });
              formData.append("file", file);
              formData.append("details", JSON.stringify(newobj));
              setStoredetailtoback({
                ...newstateofcomp,
                report_id: CustomeDetailOfReport.report_id,
           
                background_colour:
                  customizationOptionsPreview.background_colour,
                chart_react_colour:
                  customizationOptionsPreview.chart_react_colour,
                font_size_title: customizationOptionsPreview.font_size_title,
                font_size_value: customizationOptionsPreview.font_size_value,
                rule_value: customizationOptionsPreview.rule_value,
                rule_colour: customizationOptionsPreview.rule_colour,
                rule_mapping: customizationOptionsPreview.rule_mapping,
                upload_logo: CustomeDetailOfReport.upload_logo,
              });
              setCustomDetail(formData);
            });
        } else {
        
          formData.append("details", JSON.stringify(newobj));
          setStoredetailtoback({
            ...newstateofcomp,
            report_id: CustomeDetailOfReport.report_id,
      
            background_colour: customizationOptionsPreview.background_colour,
            chart_react_colour: customizationOptionsPreview.chart_react_colour,
            font_size_title: customizationOptionsPreview.font_size_title,
            font_size_value: customizationOptionsPreview.font_size_value,
            rule_value: customizationOptionsPreview.rule_value,
            rule_colour: customizationOptionsPreview.rule_colour,
            rule_mapping: customizationOptionsPreview.rule_mapping,
            upload_logo: CustomeDetailOfReport.upload_logo,
          });
          setCustomDetail(formData);
        }
      }
    }
  }, [

    customizationOptionsPreview,
  ]);

  const handelSaveChart = async () => {
    if (!CustomeDetailOfReport.report_id) {
      dispatch(savereportTemplate(CustomDetail, history));
    } else {
      localStorage.removeItem("uploadLogo");
      dispatch(updateReportdetail(CustomDetail, history));
    }
  };

  const handelbackbuttonchange = async () => {
    localStorage.setItem(
      "backcustomeDetailOfReport",
      JSON.stringify(storedetailtoback)
    );
    history("/DataFromBackPage");
  };

  return (
    <div>
      <div className="Header">
        <Header />
      </div>
      <div className="preview_page_container">
        <div className="High_chart_type">
          <div className="previchartcnt">
            {CustomeDetailOfReport?.type === "Table" ? (
              <PreviewReportTable />
            ) : CustomeDetailOfReport?.type === "Box" ? (
              <Box
       
                customizationOptionsPreview={customizationOptionsPreview}
                setCustomizationOptionsPreview={setCustomizationOptionsPreview}
              />
            ) : CustomeDetailOfReport?.type === "Chart" ? (
              <PreviewHighchart
             
                customizationOptionsPreview={customizationOptionsPreview}
                setCustomizationOptionsPreview={setCustomizationOptionsPreview}
                dragMe={dragMe}
                setDragMe={setDragMe}
              />
            ) : null}
          </div>
        </div>
        <div className="Preview_button_cnt">
          <Button type="button" onClick={handelbackbuttonchange}>
            Back
          </Button>
          <Button onClick={handelSaveChart}>Save</Button>
        </div>
      </div>
    </div>
  );
}

export default PreviewPage;
