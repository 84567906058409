import React, { useEffect, useMemo, useState } from "react";
import Header from "../header";
import { listofgroup } from "../../actions/newgroup";
import { useDispatch, useSelector } from "react-redux";
import "./../globalCSS/dashboardmanagement/dashboardmanagement.css";
import { useNavigate } from "react-router-dom";
import { Button } from "../globalCSS/Button/Button";
import {
  listofdashboardframenamewithdistiinct,
  listofaccessmask,
  updateaccessofdashboard,
} from "../../actions/canvascreation";
import styles from "./../globalCSS/SearchTable/SearchTable.module.css";
import Pagination from "../Pagination/Pagination";

// Auther:- Ashish Kumar
function DashboardManagement() {
  const dispatch = useDispatch();
  const history = useNavigate();

  // Local state variables
  const [addnewgroup, setaddnewgroup] = useState();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [listofframe, setFramedetail] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkboxStates, setCheckboxStates] = useState({}); //yuvraj

  // Fetch user data from local storage
  const user = JSON.parse(localStorage.getItem("profile"));

  // Fetch data from Redux store
  const apiData = useSelector((state) => state);
  const listofallgroup = apiData?.newgroup.list_of_group;
  
  const listofdashboardsname =
    apiData?.canvascreation?.listofdashboardcanvasframewithdisnict?.dashboards;
  const listofdash =
    apiData?.canvascreation?.listofdashboardcanvasaccess?.frame;

  const queryParameters = new URLSearchParams(window.location.search);
  const group_id = queryParameters.get("group_id");

  const [defaultDashboardName, setDefaultDashboardName] = useState(null);

  const handleDefaultCheckboxChange = (dashboardName) => {
    if (defaultDashboardName === dashboardName) {
      setDefaultDashboardName(null);
    } else {
      setDefaultDashboardName(dashboardName);
    }
  };

  // Initialize frame details based on dashboards name list
  useEffect(() => {
    if (listofdashboardsname) {
      const updatedReports =
        listofdashboardsname &&
        listofdashboardsname.map((dashboardname) => ({
          name: dashboardname.dashboard_report_name,
          read: false,
          write: false,
          adminMode: false,
        }));
      setFramedetail(updatedReports);
      setIsLoading(true);
    }
  }, [listofdashboardsname]);

  useEffect(() => {
    // if (!listofframe || listofframe.length === 0) {
    //   return;
    // }
    const selectButtonAtIndex1 = () => {
      const buttonAtIndex1 = document.getElementById(group_id);
      

      if (buttonAtIndex1) {
        buttonAtIndex1.click();
      }
    };

    // Function to handle loading and executing the button click
    const executeButtonSelection = () => {
      if (document.readyState === "complete") {
        setTimeout(selectButtonAtIndex1, 100); // Set delay here
      } else {
        window.onload = selectButtonAtIndex1;
      }
    };

    if (listofdashboardsname) {
      executeButtonSelection();
    }
  }, [group_id, listofdashboardsname, isLoading]);

  // Filter and paginate the table data
  let PageSize = 8;

  const filteredData = useMemo(() => {
    if (!search) return listofframe;
    return listofframe?.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, listofframe]);

  const paginatedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredData?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredData, PageSize]);

  const accessMap = {
    read: "r",
    write: "w",
  };

  // Handle checkbox change for read, write, and admin mode
  const handleCheckboxChange = (index, key) => {
    setFramedetail((prevReports) => {
      const updatedReports = prevReports.map((report, i) => {
        if (i === index) {
          return { ...report, [key]: !report[key] };
        }
        return report;
      });
      if (key === "adminMode") {
        // If adminMode is checked, set all other checkboxes to true
        if (updatedReports[index].adminMode) {
          updatedReports[index].read = true;
          updatedReports[index].write = true;
        } else {
          // If adminMode is unchecked, set all other checkboxes to false
          updatedReports[index].read = false;
          updatedReports[index].write = false;
        }
      } else {
        // Check if all checkboxes except adminMode are checked
        const allChecked = ["read", "write"].every(
          (checkbox) => updatedReports[index][checkbox]
        );
        updatedReports[index].adminMode = allChecked;
      }
      setCheckboxStates((prev) => ({
        ...prev,
        [index]: updatedReports[index],
      }));
      return updatedReports;
    });
  };

  // Update frame details based on access list
  useEffect(() => {
    if (listofdash) {
      const updatedReports = listofframe?.map((report) => {
        const assignedReport = listofdash?.find(
          (item) => item.dashboard_report_name === report.name
        );
        return {
          ...report,
          read: assignedReport?.access.includes("r") || false,
          write: assignedReport?.access.includes("w") || false,
          adminMode:
            (assignedReport?.access.includes("r") &&
              assignedReport?.access.includes("w")) ||
            false,
          group_id: addnewgroup,
        };
      });

      const checkupdateddashboard = listofdash?.find(
        (item) => item.default_dashboard === "default"
      );

      if (checkupdateddashboard) {
        setDefaultDashboardName(checkupdateddashboard.dashboard_report_name);
      } else {
        setDefaultDashboardName(null);
      }

      setFramedetail(updatedReports);
      setCheckboxStates(
        updatedReports.reduce((acc, item, idx) => {
          acc[idx] = item;
          return acc;
        }, {})
      );
    }
  }, [listofdash]);

  // Fetch initial data for groups and dashboard frames
  useEffect(() => {
    setDefaultDashboardName(null);
    dispatch(
      listofdashboardframenamewithdistiinct({ customer_id: user.customer_id })
    );
    dispatch(
      listofgroup({ email: user.user_email_id, database_type: "mysql" })
    );
  }, []);

  // Handle group selection
  const handleSelectGroup = async (e, groupid) => {
    dispatch(
      listofaccessmask({ customer_id: user.customer_id, group_id: groupid })
    );
    setaddnewgroup(groupid);
    console.log(groupid, "groupid");
  };

  const handelclickgotoDashboard = () => {
    history("/Dashboard");
  };

  // Handle save report action
  const handelsavereport = async () => {
    const result = listofframe.reduce((acc, item) => {
      const access = Object.keys(accessMap)
        .filter((key) => item[key])
        .map((key) => accessMap[key])
        .join("");

      acc[item.name] = access;
      return acc;
    }, {});
    const defaultDashboard = defaultDashboardName ? defaultDashboardName : null;
    if (Object.keys(result).length > 0) {
      let payloadform = {
        group_id: listofframe[0].group_id,
        database_type: "mysql",
        dashboard_access: result,
        default_dashboard: defaultDashboard,
        customer_id: user.customer_id,
      };
      if (Object.keys(payloadform).length > 0) {
        
        dispatch(updateaccessofdashboard(payloadform, history));
      }
    }
  };
  //new change
  const handleclickgotoUserManagement = () => {
    history("/UserManagementList");
  };
  //new change
  const handleClickGoBackToFeatureAssignPage = () => {
    history("/FeatureAssignpage");
  };

  return (
    <div>
      <div className="Header">
        <Header />
        <div className="DashboardManagement_report_to_group">
          <span
            className="fas fa-house-user"
            aria-hidden="true"
            onClick={handelclickgotoDashboard}
          ></span>
          {/*new change */}
          <span>/</span>
          <span
            style={{ cursor: "pointer" }}
            onClick={handleclickgotoUserManagement}
          >
            {" "}
            User Management
          </span>
          <span>/</span>
          <span
            style={{ cursor: "pointer" }}
            onClick={handleClickGoBackToFeatureAssignPage}
          >
            {" "}
            Access Features
          </span>
          {/*new change */}
          <span>/</span>
          <span> Assign Dashboard To The Group</span>
        </div>
        <div className="DashboardManagement_report_sub_container">
          <div className="DashboardManagement_report_button_cnt">
            <Button onClick={handelsavereport}>Save</Button>
          </div>
        </div>
        <div className="DashboardManagement_report_displayed">
          <div style={{ marginRight: "100px" }}>
            {listofallgroup &&
              listofallgroup?.map((group, index) => (
                <Button
                  id={group.group_id}
                  key={index}
                  style={{
                    backgroundColor:
                      group.group_id === addnewgroup ? "white" : "#424344",
                    color: group.group_id === addnewgroup ? "#424344" : "white",
                  }}
                  onClick={(e) => handleSelectGroup(e, group.group_id)}
                >
                  {group.groupname}
                </Button>
              ))}
          </div>
        </div>
        <div className="DashboardManagement_report_table_container">
          <table
            className="table table-striped table-bordered table-hover"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>
                  <span className="DashboardManagement_Report_search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      className={styles.inputSearch}
                      placeholder="Search"
                      value={search}
                      maxLength={120}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </span>
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>Name</th>
                <th style={{ textAlign: "center" }}>Default</th>

                <th style={{ textAlign: "center" }}>Read</th>

                <th style={{ textAlign: "center" }}>Write</th>

                <th style={{ textAlign: "center" }}>Admin Mode</th>
              </tr>
            </thead>

            <tbody>
              {paginatedData &&
                paginatedData.map((item, index) => {
                  const globalIndex = (currentPage - 1) * PageSize + index;
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          checked={defaultDashboardName === item.name} 
                            disabled={!(
                              checkboxStates[globalIndex] ? checkboxStates[globalIndex].read : item.read
                            )} //
                          onChange={() =>
                            handleDefaultCheckboxChange(item.name)
                          } 
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          checked={
                            checkboxStates[globalIndex]
                              ? checkboxStates[globalIndex].read
                              : item.read
                          }
                          onChange={() =>
                            handleCheckboxChange(globalIndex, "read")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          checked={
                            checkboxStates[globalIndex]
                              ? checkboxStates[globalIndex].write
                              : item.write
                          }
                          onChange={() =>
                            handleCheckboxChange(globalIndex, "write")
                          }
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          checked={
                            checkboxStates[globalIndex]
                              ? checkboxStates[globalIndex].adminMode
                              : item.adminMode
                          }
                          onChange={() =>
                            handleCheckboxChange(globalIndex, "adminMode")
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="Pagination_Container">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredData ? filteredData.length : 1}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardManagement;
