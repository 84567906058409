import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getdataforDrilldown } from "../../actions/reportmanagement";
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, IconButton, Menu, MenuItem,CircularProgress  } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import GetAppIcon from '@mui/icons-material/GetApp';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import { Tooltip } from "@mui/material";
import Typography from '@mui/material/Typography';
import _ from "lodash";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ClearAllIcon from '@mui/icons-material/ClearAll';

import { MdPictureAsPdf } from "react-icons/md";
import { FaFileExcel } from "react-icons/fa6";
import { FaFileCsv } from "react-icons/fa";


import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
 
 
function DrillDown() {
 
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [titlereport, settitleOfReport] = React.useState('');
  const [sorting, setSorting] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const history = useNavigate();
  const user = JSON.parse(localStorage.getItem("profile"));
  const apiData = useSelector((state) => state);
 
  useEffect(() => {
    const fetchData = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const reportTitle = searchParams.get("report_title");
      settitleOfReport(reportTitle);
      const categoryValue = searchParams.get("category_value");
      const selectedSeriesName = searchParams.get("selected_series_name");

      if (reportTitle && categoryValue) {
        await dispatch(
          getdataforDrilldown({
            customer_id: user?.customer_id,
            master_report: reportTitle,
            filter_value: categoryValue,
            selectedSeriesName: selectedSeriesName,
          })
        );
      } else {
        await dispatch(
          getdataforDrilldown({
            customer_id: user?.customer_id,
            master_report: reportTitle,
            filter_value: "",
          })
        );
      }
      setLoading(false); // Stop loading after data fetch
    };

    fetchData();
  }, []);

  const drilldownData = apiData?.reportmanagement.detaildatafordrilldown;
 
  function isDateTime(value) {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}[T\s]\d{2}:\d{2}(:\d{2})?$/;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateTimeRegex.test(value)) {
      return 'datetime';
    } else if (dateRegex.test(value)) {
      return 'date';
    } else {
      return false;
    }
  }
 
  const columns = useMemo(() => {
    return drilldownData?.column_names?.map(column => {
      const dateType = drilldownData?.data.some(val => isDateTime(val[column]) === 'datetime')
        ? 'datetime'
        : drilldownData?.data.some(val => isDateTime(val[column]) === 'date')
        ? 'date'
        : null;

      if (dateType === 'datetime') {
        return {
          accessorFn: (originalRow) => {
            const value = originalRow[column];
            return value ? new Date(value) : null; // Only convert to Date if value is present
        },
          id: column,
          header: column,
          filterVariant: 'datetime',
          columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals', 'brtween', 'betweenInclusive', 'greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo'],
          Cell: ({ cell }) => {
            const value = cell.getValue();
            if (!value) return ""; // Return empty string if value is null or undefined
            return `${value.toLocaleDateString()} ${value.toLocaleTimeString()}`;
        },
          filterFn: (row, id, filterValue) => {
            const rowValue = row.getValue(id);
            const [start, end] = filterValue || [];
            const startDate = start ? new Date(start) : null;
            const endDate = end ? new Date(end) : null;
 
            // Normalize rowValue and endDate to only consider the date (ignoring time)
            const normalizedRowValue = new Date(rowValue.setHours(0, 0, 0, 0));
            const normalizedEndDate = endDate ? new Date(endDate.setHours(0, 0, 0, 0)) : null;
 
            return (
              (!startDate || normalizedRowValue >= startDate) &&
              (!endDate || normalizedRowValue <= normalizedEndDate)
            );
          },
        };
      }else if(dateType === 'date'){
        return {
          accessorFn: (originalRow) => {
            const value = originalRow[column];
            return value ? new Date(value) : null;
          },
          id: column,
          header: column,
          filterVariant: 'date', 
          columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals', 'between', 'greaterThan', 'lessThan'],
          Cell: ({ cell }) => {
            const value = cell.getValue();
            if (!value) return ""; 
            return value.toLocaleDateString(); 
          },
          filterFn: (row, id, filterValue) => {
            const rowValue = row.getValue(id);
            const [start, end] = filterValue || [];
            const startDate = start ? new Date(start) : null;
            const endDate = end ? new Date(end) : null;
  
            return (!startDate || rowValue >= startDate) && (!endDate || rowValue <= endDate);
          },
        };
      }
      else {
        return {
          header: column,
          id: column,
          accessorFn: row => String(row[column]),
          columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
          enableClickToCopy: true,
          filterVariant: 'multi-select',
          filterFn: (row, id, filterValue) => {
            const cellValue = row.getValue(id);
            if (Array.isArray(filterValue) && filterValue.length === 0) {
              return true;
            }
            if (Array.isArray(filterValue)) {
              return filterValue.some(value =>
                String(cellValue).toLowerCase() === String(value).toLowerCase()
              );
            }
            return String(cellValue).toLowerCase() === String(filterValue).toLowerCase();
          },
        };
      }
    }) || [];
  }, [data, drilldownData]);
 
  useMemo(() => {
    if (drilldownData?.data) {
      setData(drilldownData.data);
    }
  }, [drilldownData]);
 
 
 
 
  const handleExportRows = (rows) => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.setFontSize(18);
    const pageWidth = doc.internal.pageSize.getWidth();
    const text = titlereport  && titlereport || "";
    const textWidth = doc.getTextWidth(text);
 
    const textX = (pageWidth - textWidth) / 2;
    doc.text(text, textX, 10);
 

    const visibleColumns = columns.filter((column) => table.getColumn(column.id).getIsVisible());
   
    const tableData = rows.map((row) =>
      visibleColumns.map((column) => _.get(row.original, column.id))
    );
    const tableHeaders = visibleColumns.map((c) => c.header);
    
 
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      styles: {
        lineColor: [44, 62, 80],
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: [255, 255, 255],
        fontStyle: 'bold',
      },
      bodyStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [44, 62, 80],
      },
      alternateRowStyles: {
        fillColor: [238, 238, 238],
      },
    });
 
    doc.save(`${titlereport}.pdf`);
  }
 
  
  const handleExportExcel = async (rows) => {
    const visibleColumns = columns.filter((column) => table.getColumn(column.id).getIsVisible());
    const tableData = rows.map((row) =>
      visibleColumns.map((column) => _.get(row.original, column.id))
    );
    const tableHeaders = visibleColumns.map((c) => c.header);
   
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('ReportData');
    worksheet.mergeCells(1, 1, 1, visibleColumns.length);
    const titleCell = worksheet.getCell('A1');
    titleCell.value = titlereport || 'Report Title';
    titleCell.font = { bold: true, size: 18 };
    titleCell.alignment = { horizontal: 'center' };
   
    // Add headers
    const headerRow = worksheet.addRow(tableHeaders);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'center' };
    });
   
    // Add table data
    tableData.forEach((dataRow) => {
      worksheet.addRow(dataRow);
    });
   
    // Adjust column widths based on header length
    visibleColumns.forEach((_, index) => {
      const column = worksheet.getColumn(index + 1);
      column.width = Math.max(10, tableHeaders[index].length + 5);
    });
   
    // Generate the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
   
    // Trigger file download
    const fileName = `${titlereport || 'Report'}.xlsx`;
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  };

  const handleExportCSV = (rows) => {

    
    const visibleColumns = columns.filter((column) => table.getColumn(column.id).getIsVisible());
   
    const csvData = rows.map((row) =>
      visibleColumns.map((column) => _.get(row.original, column.id))
    );
    const csvHeaders = visibleColumns.map((c) => c.header);
    const sheetData = [
      [`${titlereport}`],
      [],                                
      csvHeaders,                        
      ...csvData                        
    ];
 
    return {
      data: sheetData,
      filename: `${titlereport}.csv`,
    };
  };
 
 
 
 
 
  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    onShowColumnFilters: true,
    enableStickyFooter: true,
    paginationDisplayMode: "pages",
    enableColumnFilterModes: true,
    enableColumnResizing: true,
    layoutMode: 'grid',
    // enableSorting: false,
    // enableMultiSort: true,
 
    onSortingChange: setSorting,
    state: {
      sorting,
    },
    isMultiSortEvent: () => true,

    muiTableBodyCellProps: {
      sx: {
        cursor: 'pointer',
        '&:tr td hover': {
          backgroundColor: '#e0f7fa', // Row hover color
        },
      },
    },
 
    enableColumnPinning: true,
    muiTableContainerProps: {
      sx: (theme) => ({
        "td[data-pinned='true']::before,th[data-pinned='true']::before": {
          background: theme.palette.primary.main,
        },
        "td[data-pinned='true'],th[data-pinned='true']": {
          color: theme.palette.common.white,
        },
        padding: 0,
        margin: 0,
        width: '100%',
        overflowX: 'auto',
 
      }),
    },
    enableColumnOrdering: true,

    muiPaginationProps: {
      variant: "outlined",
      shape: "rounded",
      sx: (theme) => ({
        "& .Mui-selected": {
          background: `${theme.palette.primary.main} !important`,
          color: theme.palette.common.white,
        },
 
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Download">
            <IconButton onClick={handleClick} >
              <span style={{ backgroundColor: "#f9f9f9", height: "22px" }}>
                <GetAppIcon />
 
              </span>
            </IconButton>
          </Tooltip>
          <Menu
            id="export-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>
              <span><MdPictureAsPdf /> Export PDF</span>
            </MenuItem>
            <MenuItem onClick={() => handleExportExcel(table.getPrePaginationRowModel().rows)}>
              <span><FaFileExcel />Export Excel</span>
            </MenuItem>
            <MenuItem>
              <CSVLink {...handleExportCSV(table.getPrePaginationRowModel().rows)} style={{ textDecoration: 'none', color: 'inherit' }}>
                <span><FaFileCsv />Export CSV</span>
              </CSVLink>
            </MenuItem>
          </Menu>
          <Tooltip title="Clear All Sorting">
            <span>
              <Button
                size="small"
                color="primary"
 
                onClick={() => table.resetSorting()}
 
                disabled={sorting.length === 0}
              >
                <ClearAllIcon />
              </Button>
            </span>
          </Tooltip>
        </Box>
        {/* Centered and Bold Caption */}
        <Typography
          variant="h6" // Adjust variant as per your need
          sx={{ textAlign: 'center', fontWeight: 'bold', flex: 1 }} // Center and make bold
        >
          {table?.getState()?.caption || drilldownData?.report_title}
        </Typography>
      </Box>
    ),
 
    enableFacetedValues: true,
    initialState: { pagination: { pageSize: 8, pageIndex: 0 }, density: 'compact' },
 
  });
 
 
 
 return (
    <Box sx={{ px: 5, border: '1px solid #ddd', borderRadius: '4px' }}>
      {titlereport && (
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#000;', pb: 1, mb: 2, mt:2, textAlign:'center' }}>
          {titlereport}
        </Typography>
      )}
      
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box sx={{ px: 5, py: 3, border: '1px solid #ddd', borderRadius: '4px' }}>
            <MaterialReactTable table={table} sx={{ width: '100%', overflowX: 'auto', border: '2px solid #ddd', borderRadius: '10px' }} />
          </Box>
        </LocalizationProvider>
      )}
    </Box>
  );
}

 
export default DrillDown;