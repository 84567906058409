import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./NewSidebar.css";
import Logo from '../Images/hyphenwhite.png'
import Logo1 from '../Images/hyphenviewlogo.png'
import CustomDropdownNew from "./CustomDropDownNew";
import { listofgroup } from "../../actions/newgroup";
import { canvashframedataformodification } from "../../actions/canvascreation";
import userGroupIcons from "./UserGroupIcons";
import MenuButton from '@mui/joy/MenuButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckIcon from '@mui/icons-material/Check';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';

const NewSidebar = ({ sideBarWidth, setSideBarWidth, onToggle, isOpen }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state);
  const user = JSON.parse(localStorage.getItem("profile"));
  const listofallgroup = apiData?.newgroup.list_of_group;
  const [selectedUserGroup, setSelectedUserGroup] = useState(null);
  const [showGroups, setShowGroups] = useState(false);
  const [featurename, setFeaturename] = useState([]);
  const [firstSideBarDivItemHeight, setFirstSideBarDivItemHeight] = useState(0);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  

  const [showGroupDropdown, setShowGroupDropdown] = useState(false);

  const toggleGroupDropdown = (event) => {
    event.stopPropagation();
    setShowGroupDropdown(!showGroupDropdown);
  };

  useEffect(() => {
    const sideBarDiv = document.getElementById("sidebarContainerId");
    // const firstSideBarDivItem = document.getElementById(
    //   "firstSideBarDivItemtId"
    // );

    const firstSideBarDivItem = document.querySelector(
      ".firstSideBarDivItemtId"
    );

    if (firstSideBarDivItem) {
      const firstSideBarDivItemRect =
        firstSideBarDivItem.getBoundingClientRect();
      setFirstSideBarDivItemHeight(firstSideBarDivItemRect.height);
    }

    if (sideBarDiv) {
      const resizeObserver = new ResizeObserver(() => {
        const rect = sideBarDiv.getBoundingClientRect();
        setSideBarWidth(rect.width);
      });

      resizeObserver.observe(sideBarDiv);

      // Cleanup observer on component unmount
      return () => {
        resizeObserver.unobserve(sideBarDiv);
        resizeObserver.disconnect();
      };
    }
  }, []);

  const [size, setSize] = React.useState('Medium');




  useEffect(() => {
    if (user) {
      setFeaturename(user.features.filter((feturedetail) => user.group_id === feturedetail.group_id));
      setSelectedUserGroup(user.group_id);
    }
  }, []);


  const handleUserGroupChange = (value) => {
    dispatch(
      canvashframedataformodification({
        customer_id: user.customer_id,
        group_id: value,
      })
    );
    setSelectedUserGroup(value);

  };

  useEffect(() => {
    dispatch(
      canvashframedataformodification({
        customer_id: user.customer_id,
        group_id: user.group_id,
      })
    );
    dispatch(
      listofgroup({ email: user.user_email_id, database_type: "mysql" })
    );
  }, []);

  const getRandomIcon = () => {
    const randomIndex = Math.floor(Math.random() * userGroupIcons.length);
    return userGroupIcons[randomIndex];
  };

  // const dropdownOptions = user.features?.map((groupdetail) => {
  //   const icon = getRandomIcon();
  //   return {
  //     value: groupdetail.group_id,
  //     label: groupdetail.groupname,
  //     icon: icon,
  //     userfeature: user.features.filter((item) => user.group_id === item.group_id).map(item => item.group_id),
    
  //   };
  // });
  const dropdownOptions = [...new Map(
    user.features.map((groupdetail) => [groupdetail.group_id, groupdetail])
  ).values()].map((groupdetail) => {
    const icon = getRandomIcon();
    return {
      value: groupdetail.group_id,
      label: groupdetail.groupname,
      icon: icon,
      userfeature: user.features
        .filter((item) => user.group_id === item.group_id)
        .map((item) => item.group_id),
    };
  });
  
  const handelClickDashboard = () => {
    history("/DashboardNew");
  };

  const handleClickFeature = (feature) => {
    if (feature === 'User Management') {
      history('/UserManagementList')
    } else if (feature === 'Report Management') {
      history('/ListOfReports')
    } else if (feature === 'Report Scheduler') {
      history('/ReportSchedulerList')
    } else if (feature === 'Dashboard Management') {
      history('/ListOfDashboardCanvas')
    }
  }

  const handelClicklogout = () => {
    history("/profile");
  };

  const onViewGroupIconClick = (e) => {
    e.stopPropagation();
    setShowGroups(!showGroups);
  };

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handelGroupSwitch = (newGroupName, newGroupId, index) => {
    let userData = JSON.parse(localStorage.getItem('profile'));

    if (userData) {
      userData.groupname = newGroupName;
      userData.group_id = newGroupId;
      localStorage.setItem('profile', JSON.stringify(userData));
      const updatedFeatures = userData.features.filter((feature) => feature.group_id === newGroupId);
      dispatch(
        canvashframedataformodification({
          customer_id: user.customer_id,
          group_id: newGroupId,
        })
      );
      setFeaturename(updatedFeatures);
      setSelectedUserGroup(newGroupId);
      setHighlightedIndex(index)
    } else {
      console.log('No user data found in localStorage');
    }
  };

  const filteredGroups = user.features
    ?.filter((item, index, self) =>
      index === self.findIndex((t) => t.group_id === item.group_id)
    ) || [];


  return (
    <div id="sidebarContainerId" className={`sidebarcnt ${isOpen ? "open" : "closed"}`}>
      <div className="listofall-feature">
        {isOpen ? (
          <div className='New_SideBar_item firstSideBarDivItemtId' onClick={onToggle}>
            <img src={Logo} alt="Clickable Image"></img>
          </div>
        ) : (
          <div className='New_SideBar_item firstSideBarDivItemtId' onClick={onToggle} style={{ height: "64px", transition: "width 0.3s ease" }}>
            <img style={{ width: "30px", height: "30px", marginLeft: "3px" }} src={Logo1} alt="Clickable Image"></img>
          </div>
        )}
        <hr className='line_drow'></hr>
        <div id="dashboard" className="New_SideBar_item">
          <CustomDropdownNew
            firstSideBarDivItemHeight={firstSideBarDivItemHeight}
            sideBarWidth={sideBarWidth}
            isSidebarCollapsed={!isOpen}
            options={dropdownOptions}
            selectedOption={selectedUserGroup}
            onOptionSelect={handleUserGroupChange}
          />
        </div>

        {(featurename != 'undefined') && featurename?.map((feature, index) => (
          <div onClick={() => handleClickFeature(feature.featurename)} className="New_SideBar_item">
            {isOpen ? (
              <span className="dashboard_sidebar_icons_fixed">
                <img
                  className="dashboard_sidebar_icons"
                  src={feature.feature_logo}
                  title={feature.featurename}
                  alt="userManagementLogo"
                />
                <span>{feature.featurename}</span>
              </span>
            ) : (
              <span className="dashboard_sidebar_icons_fixed">
                <img
                  className="dashboard_sidebar_icons"
                  src={feature.feature_logo}
                  title={feature.featurename}
                  alt="userManagementLogo"
                />
              </span>
            )}
          </div>
        ))}
        {isOpen ? (
          <div className='New_SideBar_item' style={{ height: "64px", transition: "width 0.3s ease" }}>
            <span className="dashboard_sidebar_icons_fixed">
              <span>
                <img src="/featureIcon/1_logout.png" className="profile_logo" alt="Logout Icon" style={{ width: "30px", height: "30px" }} />
              </span>
              <Dropdown>
                <MenuButton endDecorator={<ArrowDropDown sx={{ color: 'white' }} />}
                  sx={{
                    padding: "0 !important",
                    color: 'white', border: "none",
                    '&:hover': { backgroundColor: 'transparent' },
                    '&:focus': { backgroundColor: 'transparent' },
                  }}>User Profile</MenuButton>
                <Menu
                  sx={{ minWidth: 120, border: 'none', '--ListItemDecorator-size': '24px' }}
                >
                  <MenuItem onClick={handelClicklogout}>
                    <ListItemDecorator sx={{ marginRight: '5px' }}>
                      <PersonIcon />
                    </ListItemDecorator>
                    {user.user_email_id}
                  </MenuItem>
                  <ListDivider />
                  {user.features
                    ?.filter((item, index, self) =>
                      index === self.findIndex((t) => t.group_id === item.group_id)
                    ).length > 1 && (<div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '4px 8px',
                        margin: '4px 0',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        transition: 'background-color 0.3s',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#f0f0f0';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'white';
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleGroupDropdown(e);
                      }}>
                      <ListItemDecorator sx={{ marginRight: '5px' }}>
                        <SwitchAccessShortcutIcon />
                      </ListItemDecorator>
                      <span style={{ marginLeft: "10px" }}>Switch Group</span>
                      <ListItemDecorator sx={{ marginRight: '5px' }}>
                        <ArrowDropDown />
                      </ListItemDecorator>
                    </div>)}
                  {showGroupDropdown && (
                    <ListItem nested>
                      <List aria-label="Group names">
                        {user.features
                          ?.filter((item, index, self) =>
                            index === self.findIndex((t) => t.group_id === item.group_id)
                          )
                          .map((item, index) => (
                            <MenuItem
                              key={item.group_id}
                              onClick={() => { handelGroupSwitch(item.groupname, item.group_id, index) }}
                            >
                              <ListItemDecorator sx={{ marginLeft: '15px' }}>
                                {<img src={getRandomIcon()} className="sidebar-dropdown-icon" />}
                              </ListItemDecorator>
                              <span style={{ marginLeft: "15px" }}>{item.groupname}</span>
                              {selectedUserGroup === item.group_id && (
                                <CheckIcon/>
                              )}
                            </MenuItem>
                          ))}
                      </List>
                    </ListItem>
                  )}
                  {user.features
                    ?.filter((item, index, self) =>
                      index === self.findIndex((t) => t.group_id === item.group_id)
                    ).length > 1 && (<ListDivider />)}
                  <MenuItem onClick={() => { history("/") }}>
                    <ListItemDecorator sx={{ marginRight: '5px' }}>
                      <LogoutIcon />
                    </ListItemDecorator>
                    Logout
                  </MenuItem>
                </Menu>
              </Dropdown>
            </span>
          </div>
        ) : (
          <div className="New_SideBar_item">
            <span className="dashboard_sidebar_icons_fixed" style={{ display: "flex", justifyContent: "flex-start" }}>
              <Dropdown>
                <MenuButton
                  sx={{
                    cursor: "pointer", display: "flex", alignItems: "center", border: "none",
                    marginLeft: "0",
                    paddingLeft: "0",
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:focus': {
                      backgroundColor: 'transparent',
                    },
                  }}
                ><img src="/featureIcon/1_logout.png" className="profile_logo" alt="Logout Icon" style={{ cursor: 'pointer' }} /></MenuButton>
                <Menu sx={{ minWidth: 120, border: 'none', '--ListItemDecorator-size': '24px' }}>
                  <MenuItem onClick={handelClicklogout}>
                    <ListItemDecorator sx={{ marginRight: '5px' }}>
                      <PersonIcon />
                    </ListItemDecorator>
                    {user.user_email_id}
                  </MenuItem>
                  <ListDivider />
                  {user.features
                    ?.filter((item, index, self) =>
                      index === self.findIndex((t) => t.group_id === item.group_id)
                    ).length > 1 && (<div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '4px 8px',
                        margin: '4px 0',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        transition: 'background-color 0.3s',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#f0f0f0';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'white';
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleGroupDropdown(e);
                      }}>
                      <ListItemDecorator sx={{ marginRight: '5px' }}>
                        <SwitchAccessShortcutIcon />
                      </ListItemDecorator>
                      <span style={{ marginLeft: "10px" }}>Switch Group</span>
                      <ListItemDecorator sx={{ marginRight: '5px' }}>
                        <ArrowDropDown />
                      </ListItemDecorator>
                    </div>)}
                  {showGroupDropdown && (
                    <ListItem nested>
                      <List aria-label="Group names">
                        {user.features
                          ?.filter((item, index, self) =>
                            index === self.findIndex((t) => t.group_id === item.group_id)
                          )
                          .map((item, index) => (
                            <MenuItem
                              key={item.group_id}
                              onClick={() => { handelGroupSwitch(item.groupname, item.group_id, index) }}
                            // className={`dropdown-item ${highlightedIndex === index ? "highlightedGroupOption" : ""
                            //   } ${selectedUserGroup === item.group_id ? "selectedGroupOption" : ""
                            //   }`}
                            >
                              <ListItemDecorator sx={{ marginLeft: '15px' }}>
                                {<img src={getRandomIcon()} className="sidebar-dropdown-icon" />}
                              </ListItemDecorator>
                              <span style={{ marginLeft: "15px" }}>{item.groupname}</span>
                              {selectedUserGroup === item.group_id && (
                                <CheckIcon />
                              )}
                            </MenuItem>
                          ))}
                      </List>
                    </ListItem>

                  )}
                  {user.features
                    ?.filter((item, index, self) =>
                      index === self.findIndex((t) => t.group_id === item.group_id)
                    ).length > 1 && (<ListDivider />)}
                  <MenuItem onClick={() => { history("/") }}>
                    <ListItemDecorator sx={{ marginRight: '5px' }}>
                      <LogoutIcon />
                    </ListItemDecorator>
                    Logout
                  </MenuItem>
                </Menu>
              </Dropdown>
            </span>
          </div>
        )}

      </div>
    </div>
  );
};

export default NewSidebar;
