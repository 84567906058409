import icon1 from '../../assets/UserGroupIcons/icon1.png';
import icon2 from '../../assets/UserGroupIcons/icon2.png';
import icon3 from '../../assets/UserGroupIcons/icon3.png';
import icon4 from '../../assets/UserGroupIcons/icon4.png';
import icon5 from '../../assets/UserGroupIcons/icon5.png';
 
const userGroupIcons = [
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
];
   
  export default userGroupIcons;