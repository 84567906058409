import React, { useEffect, useState } from 'react'
import styles from './../globalCSS/highcharts/boxpreview.module.css';
import iconNo from "../../assets/images/iconNo.png";
import {toast } from 'react-toastify';
function BoxPreview({ Boxdata, height, width }) {

  const [imageSrc, setImageSrc] = useState('');
  const convertBase64ToImageSrc = (base64) => {
    return `data:image/png;base64,${base64}`;
  };

  useEffect(() => {
    if (Boxdata?.logo_path) {
      const febase64data = convertBase64ToImageSrc(Boxdata.logo_path)
      fetch(febase64data)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "logo.png", { type: "image/png" });
          setImageSrc(URL.createObjectURL(file))
        });
    }
  }, [Boxdata]);



  const handleClick = () => {
    if (Boxdata?.drilldown === 'yes') {
      const databox = { report_title: Boxdata?.report_title };
      openNewWindow(databox);
    } else {
      toast.success("drilldown is not enabled !", {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,theme: "light",});
    }
  };

  let drilldownWindow = null;

  const openNewWindow = (datav) => {
    const queryString = new URLSearchParams(datav).toString();
    const url = `/drillDown?${queryString}`;

    if (drilldownWindow && !drilldownWindow.closed) {
      drilldownWindow.location.href = url;
      drilldownWindow.focus();
    } else {
      drilldownWindow = window.open(url, '_blank', 'width=600,height=400');
    }
  };


  //   return (
  //     <div>
  //       <div onClick={handleClick} style={{ width: "auto", height: "auto", padding: "10px", backgroundColor: Boxdata?.backgroung_color }}>
  //         {Boxdata.logo_path != null && <img style={{ width: "40px", height: "40px", textAlign: "center", float: "left" }} src={imageSrc} />}
  //         <p style={{ textAlign: "center", fontSize: Boxdata?.font_size_title, padding: "5px", fontWeight: "bold", color: Boxdata.chart_react_color }}>{Boxdata?.report_title}</p>
  //         <p style={{ textAlign: "center", fontSize: Boxdata?.font_size_value, padding: "5px", fontWeight: "bold", textAlign: "center", color: Boxdata.chart_react_color }}>{Boxdata?.box_value}</p>
  //       </div>
  //     </div>
  //   )
  // }
  const booleanValues = ["NO", "FALSE", "DOWN", "ICONNO"];

  const isBooleanValue = (value) => {

    if (value === null || value === undefined) {

      return false;

    }

    return booleanValues.includes(value.toString().toUpperCase());

  };



  useEffect(() => {

    if (Boxdata?.rule_value === null || Boxdata?.rule_value === undefined) {

      Boxdata.rule_value = Boxdata?.box_value;

    }

    if (Boxdata?.rule_mapping === null || Boxdata?.rule_mapping === undefined) {

      Boxdata.rule_mapping = Boxdata?.rule_value;

    }

  }, [Boxdata]);



  const renderStatusValue = (value) => {

    if (value === null || value === undefined) {

      return;

    }

    if (value.toString().toUpperCase() === "ICONNO") {

      return <img src={iconNo} alt="no icon" width="30px" height="24px" />;

    }



    return value.toString().toUpperCase();

  };



  return (
    <div
    onClick={handleClick}
    className={styles.container}
    style={{
      background: Boxdata?.backgroung_color || "#9c27b0",
      color: Boxdata?.chart_react_color || "#fff",
    }}
  >
      {Boxdata.logo_path != null && (
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src={imageSrc}
            alt="logo"
          />
        </div>
      )}
      <div className={styles.content}>
        <p
          className={styles.title}
          style={{
            fontSize: Boxdata?.font_size_title || '16px'
          }}
        >
          {Boxdata?.report_title}
        </p>
        <p
          className={styles.value}
          style={{
            fontSize: Boxdata?.font_size_value || '24px',
            color: isBooleanValue(Boxdata?.rule_mapping)

            ? Boxdata?.rule_colour

            : Boxdata?.chart_react_color,
            
          }}
        >
          {renderStatusValue(Boxdata?.box_value)}
        </p>
      </div>
    </div>
  );
}

export default BoxPreview