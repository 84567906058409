import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customPreviewChartData, savetheboxdata } from "../../actions/auth";
import { Modal, Button } from "react-bootstrap";
import styles from "./../globalCSS/previewhighcharts/box.module.css";
import "./../globalCSS/previewhighcharts/box.css";
import ColorPicker, { useColorPicker } from "react-best-gradient-color-picker";
import iconNo from "../../assets/images/iconNo.png";

function Box({
  customizationOptionsPreview,
  setCustomizationOptionsPreview
}) {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  const [gradientMode, setGradientMode] = useState("");

  const CustomeDetailOfReport = JSON.parse(
    localStorage.getItem("customeDetailOfReport")
  );

  const [imageUrl, setImageUrl] = useState("");
  const [imagefromid, setImagefromid] = useState("");
  const [imageSrc, setImageSrc] = useState("");

  const [showTextbox, setShowTextbox] = useState(false);

  const listoffontsize = ["5px", "10px", "15px", "20px", "30px", "35px"];
  const [color, setColor] = useState(
    customizationOptionsPreview?.background_colour ? customizationOptionsPreview?.background_colour : "linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)"
  );
  const { setSolid, setGradient } = useColorPicker(color, setColor);
  const convertBase64ToImageSrc = (base64) => {
    return `data:image/png;base64,${base64}`;
  };

  useEffect(() => {
    const CustomeDetailOfReport = JSON.parse(localStorage.getItem("customeDetailOfReport"));
    
    if (CustomeDetailOfReport && Object.keys(CustomeDetailOfReport)?.length > 0) {
      const base64String = localStorage.getItem("uploadLogo");
      const initialCustomizationOptions = {
        background_colour: CustomeDetailOfReport?.background_colour || "#ffffff",
        chart_react_colour: CustomeDetailOfReport?.chart_react_colour || "#000000",
        font_size_title: CustomeDetailOfReport?.font_size_title || "",
        font_size_value: CustomeDetailOfReport?.font_size_value || "",
        rule_value: CustomeDetailOfReport?.rule_value || "",
        rule_colour: CustomeDetailOfReport?.rule_colour || "#FF0000",
        rule_mapping: CustomeDetailOfReport?.rule_mapping || "",
      };
  
      setCustomizationOptionsPreview((prev) => ({
        ...prev,
        ...initialCustomizationOptions
      }));
  
      if (CustomeDetailOfReport?.report_id) {
        const febase64data = convertBase64ToImageSrc(
          CustomeDetailOfReport?.upload_logo
        );
        if (CustomeDetailOfReport?.upload_logo) {
          fetch(febase64data)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], "logo.png", { type: "image/png" });
              setImagefromid(file);
            });
        }
      }
  
      if (base64String) {
        fetch(base64String)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], "logo.png", { type: "image/png" });
            setImageUrl(file);
          });
      }
    } else {
      console.error("CustomeDetailOfReport is null or undefined.");
    }
  }, []);
  

  useEffect(() => {
    dispatch(
      customPreviewChartData({
        report_name: CustomeDetailOfReport.title,
        report_type: CustomeDetailOfReport.type,
        chart_type: CustomeDetailOfReport.chart_type,
        query: CustomeDetailOfReport.query,
        email: user.user_email_id,
        database_type: "mysql",
        connection_type: CustomeDetailOfReport.connection_type,
        schema: CustomeDetailOfReport.schema,
      })
    );
  }, []);

  useEffect(() => {

    let url;
    if (imageUrl) {

      url = URL.createObjectURL(imageUrl);
      setImageSrc(url);
    } else if (imagefromid) {

      url = URL.createObjectURL(imagefromid);
      setImageSrc(url);
    }
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [imageUrl, imagefromid]);

  const apiData = useSelector((state) => state?.auth);

  const [PreviewchartData, setPreviewchartData] = useState({});
  useEffect(() => {
    const dataToSet =
      apiData?.custom_preview_box;
    setPreviewchartData(dataToSet);
  }, [apiData]);


  const updateCustomizationOptions = (data) => {
    setCustomizationOptionsPreview((prev) => ({
      ...prev,
      ...data
    }))
  }

  return (
    <div className={styles.boxContainer}>
      <div>
        {PreviewchartData && (
          <div
            className={styles.previewBox}
            style={{
              background: customizationOptionsPreview?.background_colour,
              "--font-color": customizationOptionsPreview?.chart_react_colour,
            }}
          >
            {imageSrc ? (
              <div className={styles.previewContainer}>
                <img src={imageSrc} alt="" />
              </div>
            ) : null}
            <div className={styles.previewcontent}>
              <p
                className={styles.previewTitle}
                style={{
                  fontSize: customizationOptionsPreview?.font_size_title,
                  fontWeight: "800",
                  color: customizationOptionsPreview?.chart_react_colour,
                }}
              >
                {CustomeDetailOfReport?.title}
              </p>
              <div
                className={styles.previewContent}
                style={{
                  fontSize: customizationOptionsPreview?.font_size_value,
                  fontWeight: "800",
                  color: customizationOptionsPreview?.chart_react_colour,
                }}
              >
                {/* {renderStatusValue(ruleBox.rule_mapping)} */}
                {PreviewchartData.box_value}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.controlsContainer}>
        <div className={styles.controlItem}>
          <label>Background Color:</label>
          <select
            value={gradientMode}
            onChange={(e) => {
              setGradientMode(e.target.value);
              if (e.target.value === "SOLID") {
                setSolid();
              } else {
                setGradient();
              }
            }}
          >
            <option value="">Set Background Style</option>
            <option value="SOLID">Solid</option>
            <option value="GRADIENT">Gradient</option>
          </select>
          {gradientMode === "SOLID" && (
            <Modal
              show={gradientMode === "SOLID"}
              onHide={() => setGradientMode("")}
            >
              <div className={styles.controlModalGradientItem}>
                <label>Background Color:</label>
                <ColorPicker
                  height={200}
                  value={customizationOptionsPreview?.background_colour}
                  onChange={(color) => updateCustomizationOptions({ background_colour: color })}
                />
              </div>
            </Modal>
          )}

          {gradientMode === "GRADIENT" && (
            <Modal
              show={gradientMode === "GRADIENT"}
              onHide={() => setGradientMode("")}
            >
              <div className={styles.controlModalGradientItem}>
                <label>Background Color:</label>
                <ColorPicker
                  height={200}
                  value={customizationOptionsPreview?.background_colour}
                  onChange={(color) => updateCustomizationOptions({ background_colour: color })}
                />
              </div>
            </Modal>
          )}
        </div>
        <div className={styles.controlItem}>
          <label>Font Color:</label>
          <input
            type="color"
            value={customizationOptionsPreview?.chart_react_colour}
            onChange={(e) => updateCustomizationOptions({ chart_react_colour: e.target.value })}
          />
        </div>
        <div className={styles.controlItem}>
          <label>Select Title Font Size:</label>
          <select
            className={styles.fontsize_change}
            name="font_size_title"
            value={customizationOptionsPreview?.font_size_title}
            onChange={(e) =>
              updateCustomizationOptions({ font_size_title: e.target.value })
            }
          >
            <option value="" disabled>
              Select Font Size
            </option>
            {listoffontsize.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.controlItem}>
          <label>Select Content Font Size:</label>
          <select
            className={styles.fontsize_change}
            name="font_size_value"
            value={customizationOptionsPreview?.font_size_value}
            onChange={(e) =>
              updateCustomizationOptions({ font_size_value: e.target.value })
            }
          >
            <option value="" disabled>
              Select Font Size
            </option>
            {listoffontsize.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>

    </div>
  );
}
export default Box;