import React from "react";
import Card from "react-bootstrap/Card";
import { Button } from './globalCSS/Button/Button';
import './globalCSS/profile.css';
import { useNavigate } from "react-router-dom";
import profileManLogo from "./../assets/images/profile.png";

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("profile"));
  

  const history = useNavigate();
  const handelClicklogout = () => {
    localStorage.removeItem("profile");
    history("/");
  };

  return (
    <div id="user-profile-page" className="container-fluid">
      <div id="welcome-message">
        <h1>
          "Welcome User, to the <span id="company-title">HyphenView</span>{" "}
          family! We're thrilled to have you join us."
        </h1>
      </div>
      <div id="user-details-info-container">
        <Card id="user-details-actual-info-container">
          <Card.Img
            id="user-details-image"
            variant="top"
            src={profileManLogo}
          />
          <Card.Body id="user-details-information">
            <Card.Title id="groupName">{user.groupname}</Card.Title>
            <Card.Text id="userName">{user.user_email_id}</Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div id="logout-button-container">
      <Button variant="dark" type="button" onClick={()=>{history('/Dashboard')}}>
          Back
      </Button>
      <Button variant="dark" type="button" onClick={handelClicklogout}>
          Logout
     </Button>
      </div>
    </div>
  );
};

export default Profile;
