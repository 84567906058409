import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';


const ProtectedRoute = ({ Component }) => {

    const user = JSON.parse(localStorage.getItem('profile'));
    const location = useLocation();

    if (!user || !user.group_id) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return <Component />;
};

export default ProtectedRoute;
