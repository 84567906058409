import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Tooltip,
    Slider
} from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import GetAppIcon from '@mui/icons-material/GetApp';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import _ from "lodash";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { MdPictureAsPdf } from "react-icons/md";
import { FaFileExcel, FaFileCsv } from "react-icons/fa";
import './../globalCSS/dashboardreport/dashboardreport.css';
import Header from '../header';
import { utils, writeFile } from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
 
function DashboardReport({ TableData ,height}) {
    
    const [data, setData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [sorting, setSorting] = useState([]);
    const open = Boolean(anchorEl);
 
    const isDateTime = (value) => {
        const dateTimeRegex = /^\d{4}-\d{2}-\d{2}[T\s]\d{2}:\d{2}(:\d{2})?$/;
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        return dateTimeRegex.test(value) || dateRegex.test(value);
    };
 
   
    useEffect(() => {
        if (TableData?.data.length > 0) {
            setData(TableData.data);
        }
    }, [TableData]);
 
    const columns = useMemo(() => {
        return TableData?.column_names
            ?.filter(column => TableData?.data.some(val => val[column] !== null && val[column] !== undefined)).map(column => {
                const isDateColumn = TableData?.data.some(val => isDateTime(val[column]));
                const isIntegerColumn = TableData?.data.every(val => Number.isInteger(val[column]));
 
                if (isDateColumn) {
                    return {
                        accessorFn: (originalRow) => {
                            const value = originalRow[column];
                            return value ? new Date(value) : null; // Only convert to Date if value is present
                        },
                        id: column,
                        header: column,
                        // filterVariant: 'datetime',
                        // columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals', 'brtween', 'betweenInclusive', 'greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo'],
                        Cell: ({ cell }) => {
                            const value = cell.getValue();
                            if (!value) return ""; // Return empty string if value is null or undefined
                            return `${value.toLocaleDateString()} ${value.toLocaleTimeString()}`;
                        },
                        filterFn: (row, id, filterValue) => {
                            const rowValue = row.getValue(id);
                            const [start, end] = Array.isArray(filterValue) ? filterValue : [];
                            const startDate = start ? new Date(start) : null;
                            const endDate = end ? new Date(end) : null;
                            const normalizedRowValue = new Date(rowValue.setHours(0, 0, 0, 0));
                            const normalizedEndDate = endDate ? new Date(endDate.setHours(0, 0, 0, 0)) : null;
 
                            return (
                                (!startDate || normalizedRowValue >= startDate) &&
                                (!endDate || normalizedRowValue <= normalizedEndDate)
                            );
                        },
                    };
                } else if (isIntegerColumn) {
                    return {
                        accessorFn: row => Number(row[column]),
                        id: column,
                        header: column,
                        // filterVariant: 'range-slider',
                        Cell: ({ cell }) => cell.getValue(),
                        Filter: ({ column: { getFilterValue, setFilterValue, getFacetedMinMaxValues } }) => {
                            const [min, max] = getFacetedMinMaxValues() || [0, 100];
                            const [minValue, maxValue] = getFilterValue() || [min, max];
 
                            return (
                                <Box >
                                    <Slider
                                        value={[minValue, maxValue]}
                                        onChange={(_, newValue) => setFilterValue(newValue)}
                                        valueLabelDisplay="auto"
                                        min={min}
                                        max={max}
                                    />
                                </Box>
                            );
                        },
                        filterFn: (row, id, filterValue) => {
                            const rowValue = row.getValue(id);
                            const [min, max] = filterValue || [];
                            return rowValue >= min && rowValue <= max;
                        },
                    };
                } else if (column != '' || column != null) {
                    return {
                        header: column,
                        id: column,
                        accessorFn: row => String(row[column]),
                        // columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith', 'endsWith', 'equals', 'notEquals'],
                        enableClickToCopy: true,
                        // filterVariant: 'multi-select',
                        filterFn: (row, id, filterValue) => {
                            const cellValue = row.getValue(id);
                            if (Array.isArray(filterValue) && filterValue.length === 0) {
                                return true;
                            }
                            if (Array.isArray(filterValue)) {
                                return filterValue.some(value =>
                                    String(cellValue).toLowerCase() === String(value).toLowerCase()
                                );
                            }
                            return String(cellValue).toLowerCase() === String(filterValue).toLowerCase();
                        },
                        muiTableBodyCellProps: {
                            sx: {
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                flexGrow: 1,
                            }
                        },
                        muiTableHeadCellProps: {
                            sx: {
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                flexGrow: 1,
                            }
                        }
                    };
                }
            }) || [];
    }, [data, TableData]);
 
 
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
 
    const handleExportRows = (rows) => {
        const doc = new jsPDF({ orientation: "landscape" });
        doc.setFontSize(18);
        const pageWidth = doc.internal.pageSize.getWidth();
        const text = TableData?.title || "";
        const textWidth = doc.getTextWidth(text);
        const textX = (pageWidth - textWidth) / 2;
        doc.text(text, textX, 10);
 
        const tableData = rows.map((row) =>
            columns.map((column) => _.get(row.original, column.header))
        );
        const tableHeaders = columns.map((c) => c.header);
 
        autoTable(doc, {
            head: [tableHeaders],
            body: tableData,
            styles: {
             
              cellPadding: 1,
              lineColor: [44, 62, 80],
              lineWidth: 0.1,
            },
            headStyles: {
              fillColor: [41, 128, 185],
              textColor: [255, 255, 255],
              fontStyle: 'bold',
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineColor: [44, 62, 80],
            },
            alternateRowStyles: {
              fillColor: [238, 238, 238],
            },
          });

        doc.save(`${TableData?.title}.pdf`);
    };
 
    const handleExportExcel = async (rows) => {
        const visibleColumns = columns.filter((column) => table.getColumn(column.id).getIsVisible());
        const tableData = rows.map((row) =>
          visibleColumns.map((column) => _.get(row.original, column.id))
        );
        const tableHeaders = visibleColumns.map((c) => c.header);
       
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('ReportData');
        worksheet.mergeCells(1, 1, 1, visibleColumns.length);
        const titleCell = worksheet.getCell('A1');
        titleCell.value = TableData?.title? TableData?.title: 'Report Title';
        titleCell.font = { bold: true, size: 18 };
        titleCell.alignment = { horizontal: 'center' };
       
        // Add headers
        const headerRow = worksheet.addRow(tableHeaders);
        headerRow.eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { horizontal: 'center' };
        });
       
        // Add table data
        tableData.forEach((dataRow) => {
          worksheet.addRow(dataRow);
        });
       
        // Adjust column widths based on header length
        visibleColumns.forEach((_, index) => {
          const column = worksheet.getColumn(index + 1);
          column.width = Math.max(10, tableHeaders[index].length + 5);
        });
       
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
       
        // Trigger file download
        const fileName = `${TableData?.title? TableData?.title:'Report'}.xlsx`;
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, fileName);
      };
 
    const handleExportCSV = (rows) => {
        const csvData = rows.map((row) =>
            columns.map((column) => _.get(row.original, column.header))
        );
        const csvHeaders = columns.map((c) => c.header);
        return {
            data: csvData,
            headers: csvHeaders,
            filename: `${TableData?.title}.csv`,
        };
    };
 
     const table = useMaterialReactTable({
        columns,
        data,
        enableStickyHeader: false,
        enableStickyFooter: true,
        paginationDisplayMode: "pages",
        enableHiding: false,
        // enableColumnFilterModes: true,
        enableColumnVisibility:false,
        enableFilters:false,    
        enableGlobalFilter:false,      
        enableDensityToggle:false,        
        enableFullScreenToggle:false,  
        enableColumnResizing:false,
        // layoutMode: 'grid',
       
        // onSortingChange: setSorting,
        // state: { sorting },
        // isMultiSortEvent: () => true,
        muiTableProps: {
            sx: (theme) => ({
                "& td[data-pinned='true'],th[data-pinned='true']": {
                    color: theme.palette.common.white,
                },
                padding: 0,
                margin: 0,
                width: '100%',
                overflowX: 'auto',
            }),
        },
        // enableColumnOrdering: true,
        //enableRowOrdering: true,
        muiRowDragHandleProps: ({ table }) => ({
            onDragEnd: () => {
                const { draggingRow, hoveredRow } = table.getState();
                if (hoveredRow && draggingRow) {
                    data.splice(
                        hoveredRow.index,
                        0,
                        data.splice(draggingRow.index, 1)[0]
                    );
                    setData([...data]);
                }
            },
        }),
        muiPaginationProps: {
            variant: "outlined",
            shape: "rounded",
            sx: (theme) => ({
                "& .Mui-selected": {
                    background: `${theme.palette.primary.main} !important`,
                    color: theme.palette.common.white,
                },
            }),
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Download">
                        <IconButton onClick={handleClick}>
                            <span style={{ backgroundColor: "#f9f9f9", height: "22px" }}>
                                <GetAppIcon color="primary" />
                            </span>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="export-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <MenuItem onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>
                            <MdPictureAsPdf color="primary" /> Export PDF
                        </MenuItem>
                        <MenuItem onClick={() => handleExportExcel(table.getPrePaginationRowModel().rows)}>
                            <FaFileExcel color="primary" /> Export Excel
                        </MenuItem>
                        
                        <MenuItem>
                            <CSVLink {...handleExportCSV(table.getPrePaginationRowModel().rows)} style={{ fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',fontSize:'1rem', fontWeight:'400', textDecoration: 'none', color: 'inherit' }}>
                                <FaFileCsv color="primary" />Export CSV
                            </CSVLink>
                        </MenuItem>
                    </Menu>
                    {/* <Tooltip title="Clear All Sorting">
                        <span>
                            <Button
                                size="small"
                                color="primary"
                                onClick={() => table.resetSorting()}
                                disabled={sorting.length === 0}
                            >
                                <ClearAllIcon />
                            </Button>
                        </span>
                    </Tooltip> */}
                </Box>
                <Typography
                    variant="h6"
                    sx={{ textAlign: 'center', fontWeight: 'bold', flex: 1 }}
                >
                    {table?.getState()?.caption || TableData?.title}
                </Typography>
            </Box>
        ),
        // enableFacetedValues: true,
        initialState: { pagination: { pageSize: 5, pageIndex: 0 }, density: 'compact' },
    });
 
 
 
    return (
        <div>
            <div className='DashboardReport_table_main_container'>
                <div className='DashboardReport_table_export_rearch'>
                    <div className="has-search report_search">
                    </div>
                </div>
                <div style={{ height: 400, width: '100%' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box sx={{py: 3, border: '1px solid #ddd', borderRadius: '4px' ,height:{height},overflowY:"scroll"}}>
                            <MaterialReactTable
                                table={table}
                                sx={{ width: '100%', overflowX: 'auto', border: '2px solid #ddd', borderRadius: '10px' }}
                                className="horizontal-stripe-table"
                            />
                        </Box>
                    </LocalizationProvider>
                </div>
            </div>
        </div>
    );
}
 
export default DashboardReport;